import cn from 'classnames'
import React, { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'

import { INNER_CLASSES } from '@const/consts'

import { Spinner } from '@infologistics/frontend-libraries'

import { getDocumentFile } from '@store/modules/shared/actions'
import { displayErrorNotification } from '@utils/utils'

import { IPreviewData } from '@store/modules/shared/types'
import { IApplicationState } from '@store/types'
import {
  IFileProps as IProps,
  IFilePropsFromDispatch as IPropsFromDispatch,
  IFilePropsFromState as IPropsFromState,
  IFileState as IState
} from './types'

const initialState: IState = {
  isPageLoading: false
}

const styles = require('./File.module.css')

class File extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  public componentDidMount (): void {
    this.loadPage()
  }

  public render (): ReactNode {
    const { documentFiles, isMobile } = this.props
    const { isAvailable } = documentFiles

    return (
      <div className={`mb-n4 bg-muted-200 ${isMobile ? 'py-4' : 'py-6'}`}>
        <div className={cn(INNER_CLASSES, 'relative', 'd-flex', 'flex-column', 'align-items-center')}>
          {isAvailable ? (
            <>
              {this.renderPages()}
              {this.renderLoader()}
            </>
          ) : (
            this.renderPlaceholder()
          )}
        </div>
      </div>
    )
  }

  private readonly renderLoader = (): ReactNode => {
    const { documentFiles } = this.props
    const { data, total } = documentFiles

    return (
      data.length < total && (
        <VisibilitySensor delayedCall={true} onChange={this.loadMorePages} partialVisibility={true}>
          <>{this.renderPlaceholder()}</>
        </VisibilitySensor>
      )
    )
  }

  private readonly renderPages = (): ReactNode => {
    const { documentFiles } = this.props
    const { data } = documentFiles

    const base64Png = 'data:image/png;base64'

    return data.length
      ? data.map((page: IPreviewData, id) => {
        const { fileCode, orientation = 'PORTRAIT' } = page

        const src = `${base64Png}, ${fileCode}`

        const classes = cn(styles.image_wrap, styles[orientation.toLocaleLowerCase()], 'relative', 'mb-4')

        return (
          <div className={classes} key={id}>
            <img className={styles.image} src={src} alt='' />
          </div>
        )
      })
      : this.renderPlaceholder()
  }

  private readonly renderPlaceholder = (): ReactNode => {
    const { documentFiles, t } = this.props
    const { isAvailable } = documentFiles

    return (
      <div className={styles.portrait}>
        <div className={cn(styles.placeholder, 'relative', !isAvailable && styles.missing)}>
          <div className={cn(styles.placeholder_content, 'absolute', 'atl-0', 'd-flex', 'flex-column', 'align-items-center')}>
            {isAvailable && (
              <div className={styles.placeholder_spinner}>
                <Spinner />
              </div>
            )}
            <p className={cn(styles.placeholder_text, 'text-muted', 'h5', 'mt-5')}>
              {t(`document:preview.${isAvailable ? 'loading' : 'missing'}`)}
            </p>
          </div>
        </div>
      </div>
    )
  }

  private readonly loadPage = (): void => {
    const { documentOguid, onGetDocumentFile, orgOguid } = this.props

    if (documentOguid && orgOguid) {
      this.setState({
        isPageLoading: true
      })

      onGetDocumentFile(orgOguid, documentOguid)
        .then(() => {
          this.setState({
            isPageLoading: false
          })
        })
        .catch((err) => {
          if (!err.response) return

          displayErrorNotification(err)
        })
    }
  }

  private readonly loadMorePages = (isVisible: boolean): void => {
    const { isPageLoading } = this.state

    if (isVisible && !isPageLoading) {
      this.loadPage()
    }
  }
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  document: state.shared.document,
  documentFiles: state.shared.documentFiles,
  documentOguid: state.utils.documentOguid,
  orgOguid: state.utils.orgOguid
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetDocumentFile: (orgOguid: string, docOguid: string) => dispatch(getDocumentFile(orgOguid, docOguid))
})

const FileClass = connect(mapStateToProps, mapDispatchToProps)(File)

export default withTranslation()(FileClass)
