import React, { Component, ReactElement, ReactNode } from 'react'
import AnimateHeight from 'react-animate-height'
import { withTranslation } from 'react-i18next'

import { Button } from '@infologistics/frontend-libraries'
import HistoryItem from './components/HistoryItem'

import { countDisplayHistoryItems, durationShowHistoryAnimation } from './consts'

import { IHistoryItem } from '@store/modules/shared/types'
import {
  IHistoryProps as IProps,
  IHistoryState as IState
} from './types'

const initialState: IState = {
  isShowedFullHistory: false
}

class History extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  public render(): ReactNode {
    const { history, t } = this.props

    return (
      <div className='mb-7'>
        <p className='my-0 h5 fw-600'>
          {t('document:history.title')}
        </p>
        {this.renderItems(history)}
      </div>
    )
  }

  private readonly renderItems = (history: IHistoryItem[]): ReactElement => {
    const { isMobile } = this.props
    const { isShowedFullHistory } = this.state

    const isShowButton = history.length > countDisplayHistoryItems

    return (
      <div>
        {
          history.slice(0, countDisplayHistoryItems).map((item, id) => (
            <HistoryItem
              historyItem={item}
              index={id}
              isMobile={isMobile}
              isShowedFullHistory={isShowedFullHistory}
              key={id}
            />
          ))
        }
        {isShowButton && (
          <>
            <AnimateHeight duration={durationShowHistoryAnimation} height={isShowedFullHistory ? 'auto' : 0}>
              {history.slice(countDisplayHistoryItems, history.length).map((item, id) => (
                <HistoryItem
                  historyItem={item}
                  index={id}
                  isMobile={isMobile}
                  isShowedFullHistory={isShowedFullHistory}
                  key={id}
                />
              ))}
            </AnimateHeight>
            {this.renderButton()}
          </>
        )}
      </div>
    )
  }

  private readonly renderButton = (): ReactNode => {
    const { t } = this.props
    const { isShowedFullHistory } = this.state

    const text = isShowedFullHistory ? t('document:history.collapse') : t('document:history.showFull')

    return (
      <Button classes='mt-4' onClick={this.handleSwitchShowHistory} theme='link'>
        {text}
      </Button>
    )
  }

  private readonly handleSwitchShowHistory = (): void => {
    this.setState((prevState) => ({
      ...prevState,
      isShowedFullHistory: !prevState.isShowedFullHistory
    }))

    const btn = document.activeElement

    if (btn instanceof HTMLButtonElement) {
      btn.blur()
    }
  }
}

export default withTranslation()(History)

