import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Nullable } from '@app/types'
import { IApplicationState } from '@store/types'
import { HistoryStateGroup, Severity } from '@const/consts'

export enum SharedActionTypes {
  SET_DOCUMENT = 'SET_DOCUMENT',
  SET_DOCUMENT_FILES = 'SET_DOCUMENT_FILES',
}

export interface IDocument {
  allowedActions: AllowedActions[]
  comment: Nullable<string>
  fields: IFields
  history: IHistoryItem[]
  initiator: Nullable<IUser>
  isExternal: boolean
  orgName: string
  subOrgName: Nullable<string>
  typeTitle: string
}

export interface IUser {
  name: Nullable<string>
  patronymic: Nullable<string>
  position: Nullable<string>
  surname: string
}

export enum AllowedActions {
  DECLINE_SIGN = 'DECLINE_SIGN',
  SIGN = 'SIGN'
}

export enum DocumentDirection {
  INCOMING = 'INCOMING',
  INTERNAL = 'INTERNAL',
  OUTGOING = 'OUTGOING'
}

export enum FieldType {
  BOOLEAN = 'BOOLEAN',
  CONTRACTOR = 'CONTRACTOR',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  DICTIONARY = 'DICTIONARY',
  DIRECTION = 'DIRECTION',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export type FieldValue = Nullable<boolean | number | string>

export interface IField {
  label: string
  order: number
  type: FieldType
  value: FieldValue
}

export interface IFields {
  [key: string]: IField
}

export interface IHistoryItem {
  comment: Nullable<string>
  group: HistoryStateGroup
  historyStateCaption: string
  historyStateCode: string
  historyTimestamp: Nullable<number>
  orgWhereHappenedName: string
  severity: Severity
  signatureCheck: Nullable<HistoryItemSignatureCheck>
  subOrgName: Nullable<string>
  user: Nullable<IUser>
}

export enum HistoryItemSignatureCheck {
  INVALID_SIGNATURE = 'INVALID_SIGNATURE',
  NOT_ACCEPTABLE = 'NOT_ACCEPTABLE',
  VALID_SIGNATURE = 'VALID_SIGNATURE',
  WAITING_FOR_CHECK = 'WAITING_FOR_CHECK'
}

export type PreviewOrientation = 'PORTRAIT' | 'LANDSCAPE'

export interface IPreviewData {
  fileCode: string
  orientation: string
}

export interface IDocumentFiles {
  data: IPreviewData[]
  isAvailable: boolean
  total: string | number
}

export enum SignResult {
  DECLINED = 'DECLINED',
  SOLVED = 'SOLVED'

}

export interface IActionSetDocument extends Action<string> {
  payload: IDocument
  type: SharedActionTypes.SET_DOCUMENT
}

export interface IActionSetDocumentFiles extends Action<string> {
  payload: IDocumentFiles
  type: SharedActionTypes.SET_DOCUMENT_FILES
}

export type Actions = IActionSetDocument | IActionSetDocumentFiles

type ActionKeys = 'setDocument' | 'setDocumentFiles'

export interface ISharedState {
  document: Nullable<IDocument>
  documentFiles: IDocumentFiles
}

export type SharedActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type SharedThunkAction = ThunkAction<Promise<AxiosResponse> | any, IApplicationState, void, Action<string>>

export type SharedThunkDispatch = ThunkDispatch<ISharedState, any, Action<string>>
