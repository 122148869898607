import i18n, { TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import * as Sentry from '@sentry/browser'

import { Instance, Languages, NS } from '@const/consts'
import { getInstance, getLanguageByInstance } from '@utils/utils';

const instance = getInstance()

const isRussia = instance === Instance.RU || instance === Instance.EU
const allLangs = [Languages.EN, Languages.RU, Languages.PT]
const langsWithoutRussian = [Languages.EN, Languages.PT]

let translates: string[] = langsWithoutRussian

if (isRussia) {
  translates = allLangs
}

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: getLanguageByInstance() ?? Languages.EN,
    fallbackLng: translates,
    preload: translates,
    ns: NS,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    debug: false,

    react: {
      nsMode: 'default'
    },

    interpolation: {
      escapeValue: false
    }
  })
  .catch((err: TFunction) => {
    const errorText = 'i18n error'
    const errorLevel = Sentry.Severity.Fatal
    const errorInfo = { err }

    Sentry.withScope((scope: Sentry.Scope) => {
      scope.setLevel(errorLevel)
      scope.setExtras(errorInfo)
      Sentry.captureException(new Error(errorText))
    })
  })

export default i18n
