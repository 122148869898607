import cn from 'classnames'
import React, { Component, ReactElement, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'

import { i18nEvent, lookupLocalStorage } from '@const/consts'
import { Language } from '@const/translations'

import { DropdownButton, IconFlagEn, IconFlagRu, IconFlagBr } from '@infologistics/frontend-libraries'

import {
  ILanguageSelectorProps as IProps,
  ILanguageSelectorState as IState,
  IIconData,
  ILanguagesDropdownItems,
  ILanguageNames
} from './types'

const styles = require('./LanguageSelector.module.css')

const initialState: IState = {
  dropdownItems: {},
  languageNames: {}
}

class LanguageSelector extends Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  public componentDidMount(): void {
    this.setStateData()
  }

  public render (): ReactElement {
    const {
      classes,
      i18n
    } = this.props

    const { language } = i18n

    i18n.on(i18nEvent.LANGUAGE_CHANGED, this.setStateData)

    const {
      dropdownItems,
      languageNames
    } = this.state

    const currentLanguageName = languageNames[language]
    const iconProps: IIconData = {
      classes: 'mr-2',
      hint: currentLanguageName,
      key: language,
      language
    }

    const dropdownTitle: ReactNode = (
      <span className='d-inline-flex text-muted-600'>
        {this.getFlagIcon(iconProps)}
        {currentLanguageName}
      </span>
    )

    return (
      <div className={cn(styles.language_selector, classes)}>
        <DropdownButton
          buttonTheme='text'
          classes={styles.dropdown}
          dropdownItems={Object.values(dropdownItems)}
          onSelect={this.handleLanguageChange}
          selectedItem={dropdownItems[language]}
          title={dropdownTitle}
          withoutArrow
        />
      </div>
    )
  }

  private readonly handleLanguageChange = (langKey: string): Promise<void> => {
    const { i18n } = this.props

    return i18n.changeLanguage(langKey)
      .then(() => window.localStorage.setItem(lookupLocalStorage, langKey))
  }

  private readonly getLanguagesDropdownItems = (languageNames: ILanguageNames): ILanguagesDropdownItems => {
    const {
      i18n: {
        language,
        languages
      }
    } = this.props

    return [ ...languages ].sort().reduce((acc: ILanguagesDropdownItems, lang) => {
      const currentLanguageName = languageNames[lang]
      const iconProps: IIconData = {
        classes: 'mr-2',
        hint: currentLanguageName,
        key: language,
        language: lang
      }

      const element: ReactNode = (
        <span className='d-inline-flex'>
          {this.getFlagIcon(iconProps)}
          <span className='mr-auto'>
            {currentLanguageName}&nbsp;({lang.toUpperCase()})
          </span>
        </span>
      )

      return {
        ...acc,
        [lang]: {
          element,
          name: lang
        }
      }
    }, {})
  }

  private readonly getFlagIcon = ({ language, ...restProps }: IIconData): ReactElement => {
    const flag = {
      en: <IconFlagEn {...restProps} />,
      ru: <IconFlagRu {...restProps} />,
      pt: <IconFlagBr {...restProps} />
    }

    return flag[language]
  }

  private readonly getLanguageNames = (languages: readonly string[]): ILanguageNames => {
    const { t } = this.props

    return languages.reduce(
      (acc, language) => ({
        ...acc,
        [language]: t(Language[language])
      }),
      {}
    )
  }

  private readonly setStateData = (): void => {
    const { i18n } = this.props

    const languageNames = this.getLanguageNames(i18n.languages)
    const dropdownItems = this.getLanguagesDropdownItems(languageNames)

    this.setState({
      dropdownItems,
      languageNames
    })
  }
}

export default withTranslation(['language'])(LanguageSelector)

