import { DocumentDirection, SignResult } from '@store/modules/shared/types'

const mockTransFunc = (arg: string) => arg

export const DocumentSignResults = {
  [SignResult.DECLINED]: mockTransFunc('document:signResult.declined'),
  [SignResult.SOLVED]: mockTransFunc('document:signResult.solved')
}

export const DocumentDirections = {
  [DocumentDirection.INCOMING]: mockTransFunc('document:direction.incoming'),
  [DocumentDirection.INTERNAL]: mockTransFunc('document:direction.internal'),
  [DocumentDirection.OUTGOING]: mockTransFunc('document:direction.outgoing')
}

export const Language = {
  en: mockTransFunc('language:en'),
  ru: mockTransFunc('language:ru'),
  pt: mockTransFunc('language:pt')
}
