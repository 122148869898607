import cn from 'classnames'
import React, { FC } from 'react'

const styles = require('./Logo.module.css')

import { ILogoProps as IProps } from './types'

const Logo: FC<IProps> = ({ classes }) => {
  return (
    <picture className={cn(styles.logo, 'd-inline-flex', 'align-items-center', classes)}>
      <source srcSet="/assets/img/logo.svg" media="(min-width: 768px)" />
      <img src='/assets/img/logo_mobile.svg' alt='docuForce' />
    </picture>
  )
}

export default Logo
