import { Action, ActionCreator } from 'redux'

import {
  IActionSetDocumentOguid,
  IActionSetOrganizationOguid,
  IActionToggleMenus,
  UtilsActions,
  UtilsActionTypes
} from './types'

const setDocumentOguidAction: ActionCreator<Action> = (oguid: string): IActionSetDocumentOguid => ({
  payload: oguid,
  type: UtilsActionTypes.SET_DOCUMENT_OGUID
})

const setOrganizationOguidAction: ActionCreator<Action> = (oguid: string): IActionSetOrganizationOguid => ({
  payload: oguid,
  type: UtilsActionTypes.SET_ORGANIZATION_OGUID
})

const toggleMenusAction: ActionCreator<Action> = (data: boolean): IActionToggleMenus => ({
  payload: data,
  type: UtilsActionTypes.TOGGLE_MENUS
})

export const actions: UtilsActions = {
  setDocumentOguid: setDocumentOguidAction,
  setOrganizationOguid: setOrganizationOguidAction,
  toggleMenus: toggleMenusAction
}
