import { AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IDocument } from '@store/modules/shared/types'

class SharedService extends AbstractHttpService {
  async getDocument (orgOguid: string, docOguid: string): Promise<AxiosResponse<IDocument>> {
    const url = urls.shared.document
      .replace('{{ orgOguid }}', orgOguid)
      .replace('{{ docOguid }}', docOguid)

    const response: AxiosResponse<IDocument> = await this._http.get(url)

    return response
  }

  async getFile (orgOguid: string, docOguid: string, page: number): Promise<AxiosResponse> {
    const url = urls.shared.page
      .replace('{{ orgOguid }}', orgOguid)
      .replace('{{ docOguid }}', docOguid)
      .replace('{{ page }}', String(page))

    const response: AxiosResponse = await this._http.get(url, {
      responseType: 'text'
    })

    return response
  }

  async signDocument (orgOguid: string, docOguid: string, result: string): Promise<AxiosResponse<IDocument>> {
    const url = urls.shared.sign
      .replace('{{ orgOguid }}', orgOguid)
      .replace('{{ docOguid }}', docOguid)

    const response: AxiosResponse<IDocument> = await this._http.post(url, { result })

    return response
  }
}

export default new SharedService()
