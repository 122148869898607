import * as Sentry from '@sentry/browser'


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './i18n'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV
  })
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
