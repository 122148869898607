import { Action, ActionCreator } from 'redux'
import { Nullable } from '@app/types'

export enum UtilsActionTypes {
  SET_DOCUMENT_OGUID = 'SET_DOCUMENT_OGUID',
  SET_ORGANIZATION_OGUID = 'SET_ORGANIZATION_OGUID',
  TOGGLE_MENUS = 'TOGGLE_MENUS'
}

export interface IActionSetDocumentOguid extends Action<string> {
  type: UtilsActionTypes.SET_DOCUMENT_OGUID
  payload: string
}

export interface IActionSetOrganizationOguid extends Action<string> {
  type: UtilsActionTypes.SET_ORGANIZATION_OGUID
  payload: string
}

export interface IActionToggleMenus extends Action<string> {
  type: UtilsActionTypes.TOGGLE_MENUS
  payload: boolean
}

export type Actions =
  IActionSetDocumentOguid |
  IActionSetOrganizationOguid |
  IActionToggleMenus

type ActionKeys = 'setDocumentOguid' | 'setOrganizationOguid' | 'toggleMenus'

export interface IUtilsState {
  documentOguid: Nullable<string>
  isShownMenus: boolean
  orgOguid: Nullable<string>
}

export type UtilsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

