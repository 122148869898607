import { Actions, SharedActionTypes, ISharedState } from './types'

export const initialState: ISharedState = {
  document: null,
  documentFiles: {
    data: [],
    isAvailable: true,
    total: 0
  }
}

export default function sharedReducer (state = initialState, action: Actions): ISharedState {
  switch (action.type) {
    case SharedActionTypes.SET_DOCUMENT:
      return {
        ...state,
        document: action.payload
      }

    case SharedActionTypes.SET_DOCUMENT_FILES:
      return {
        ...state,
        documentFiles: action.payload
      }

    default:
      return state
  }
}
