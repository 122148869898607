import React, { cloneElement, ReactNode } from 'react'

import { HistoryStateGroup, Severity, SEVERITY_COLORS } from '@const/consts'
import {
  IconStateApproving,
  IconStateCreating,
  IconStateDeclining,
  IconStateQuestion,
  IconStateReceiving,
  IconStateSending,
  IconStateSigning,
  IconStateUnknown,
  ILibIcon
} from '@infologistics/frontend-libraries'

export const countDisplayHistoryItems = 5

export const durationShowHistoryAnimation = 600

export const HISTORY_STATE_ICON = {
  [HistoryStateGroup.APPROVING]: <IconStateApproving />,
  [HistoryStateGroup.CREATING]: <IconStateCreating />,
  [HistoryStateGroup.DECLINING]: <IconStateDeclining />,
  [HistoryStateGroup.QUESTION]: <IconStateQuestion />,
  [HistoryStateGroup.RECEIVING]: <IconStateReceiving />,
  [HistoryStateGroup.SENDING]: <IconStateSending />,
  [HistoryStateGroup.SIGNING]: <IconStateSigning />,
  [HistoryStateGroup.UNKNOWN]: <IconStateUnknown />
}

export const getHistoryStateIcon = ( group: HistoryStateGroup, severity: Severity ): ReactNode => {
  const icon = HISTORY_STATE_ICON[group]

  if (!icon) return null

  const iconProps: ILibIcon = {
    color: SEVERITY_COLORS[severity],
    size: 'md',
    style: 'round',
    type: 'solid'
  }

  return cloneElement(icon, iconProps)
}

