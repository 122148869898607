import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import sharedReducer from './modules/shared/reducer'
import utilsReducer from './modules/utils/reducer'

const composeEnhancer = composeWithDevTools({ trace: true })

export const appReducer = combineReducers({
  shared: sharedReducer,
  utils: utilsReducer
})

const store = createStore(appReducer, composeEnhancer(applyMiddleware(thunk)))

export default store
