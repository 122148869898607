import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { ResponseCode } from '@const/consts'
import SharedService from '@services/shared'

import {
  SharedActions,
  SharedActionTypes,
  SharedThunkAction,
  SharedThunkDispatch,
  IActionSetDocument,
  IActionSetDocumentFiles,
  IDocument,
  IDocumentFiles
} from './types'


const setDocumentAction: ActionCreator<Action> = (document: IDocument): IActionSetDocument => ({
  payload: document,
  type: SharedActionTypes.SET_DOCUMENT
})

const setDocumentFilesAction: ActionCreator<Action> = (files: IDocumentFiles): IActionSetDocumentFiles => ({
  payload: files,
  type: SharedActionTypes.SET_DOCUMENT_FILES
})

export const actions: SharedActions = {
  setDocument: setDocumentAction,
  setDocumentFiles: setDocumentFilesAction
}

// thunks

export const getDocument: ActionCreator<SharedThunkAction> = (orgOguid: string, docOguid: string) => {
  return (dispatch: SharedThunkDispatch): Promise<AxiosResponse<IDocument>> => (
    SharedService
      .getDocument(orgOguid, docOguid)
      .then((resp) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setDocument(resp.data))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
  )
}

export const getDocumentFile: ActionCreator<SharedThunkAction> = (orgOguid: string, docOguid: string) => {
  return (dispatch: SharedThunkDispatch, getState): Promise<AxiosResponse> => {
    const { documentFiles } = getState().shared
    const { data, total } = documentFiles

    return SharedService
      .getFile(orgOguid,docOguid, data.length + 1)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          const orientation = resp.headers['x-page-orientation']
          const totalPages = resp.headers['x-total-pages']

          const newDocumentFiles: IDocumentFiles = resp.data
            ? {
              ...documentFiles,
              data: [
                ...data,
                {
                  fileCode: resp.data,
                  orientation
                }
              ],
              total: total === 0 ? totalPages : total
            }
            : {
              ...documentFiles,
              isAvailable: false
            }

          dispatch(actions.setDocumentFiles(newDocumentFiles))
        }

        return resp
      }
    )
      .catch((err: AxiosError) => Promise.reject(err))
  }
}
