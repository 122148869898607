import cn from 'classnames'
import React, { FC, ReactElement } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { INNER_CLASSES } from '@const/consts'
import { DocumentSignResults } from '@const/translations'
import { Button } from '@infologistics/frontend-libraries'
import SharedService from '@services/shared'
import { displayErrorNotification, displaySuccessNotification } from '@utils/utils'

import { getDocument } from '@store/modules/shared/actions'

import { IApplicationState } from '@store/types'
import { ISuccessNotificationOptions } from '@utils/types'
import { AllowedActions, SignResult } from '@store/modules/shared/types'
import {
  IFooterProps as IProps,
  IFooterPropsFromState as IPropsFromState,
  IFooterPropsFromDispatch as IPropsFromDispatch
} from './types'

const styles = require('./Footer.module.css')

const Footer: FC<IProps> = (props) => {
  const { allowedActions, documentOguid, isMobile, onGetDocument, orgOguid, t } = props

  const handleButtonClick = (result: string) => () => {
    const options: ISuccessNotificationOptions = {
      content: t(DocumentSignResults[result])
    }

    if (documentOguid && orgOguid) {
      SharedService.signDocument(orgOguid, documentOguid, result)
        .then(() => {
          displaySuccessNotification(options)
        })
        .then(() => onGetDocument(orgOguid, documentOguid))
        .catch(displayErrorNotification)
    }
  }

  const renderButtons = (): ReactElement => {
    return (
      <div className={cn(INNER_CLASSES, 'd-flex', 'align-items-center', 'justify-content-end')}>
        <Button
          classes={cn(styles.button, isMobile ? 'mr-2' : 'mr-4')}
          isDisabled={!allowedActions.includes(AllowedActions.SIGN)}
          onClick={handleButtonClick(SignResult.SOLVED)}
          theme='success'
        >
          {t('document:action.sign')}
        </Button>
        <Button
          classes={styles.button}
          isDisabled={!allowedActions.includes(AllowedActions.DECLINE_SIGN)}
          onClick={handleButtonClick(SignResult.DECLINED)}
          theme='danger'
        >
          {t('document:action.declineSign')}
        </Button>
      </div>
    )
  }

  return (
    <footer className={cn(styles.footer, 'fixed', 'd-flex')}>
      {renderButtons()}
    </footer>
  )
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  documentOguid: state.utils.documentOguid,
  orgOguid: state.utils.orgOguid
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetDocument: (orgOguid: string, docOguid: string) => dispatch(getDocument(orgOguid, docOguid))
})

const FooterClass = connect(mapStateToProps, mapDispatchToProps)(Footer)

export default withTranslation()(FooterClass)
