import cn from 'classnames'
import { DateTime } from 'luxon'
import React, { Component, ReactElement, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'

import { Button, Comment, LibCommentType } from '@infologistics/frontend-libraries'

import { countDisplayHistoryItems, getHistoryStateIcon } from '../../consts'
import { DateFormat } from '@const/consts'
import { getSurnameWithInitials } from '@utils/utils'

import {
  IHistoryItemProps as IProps,
  IHistoryItemState as IState
} from './types'

const initialState: IState = {
  isCommentOpen: false
}

const styles = require('../../History.module.css')

class HistoryItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  public render(): ReactElement {
    const {
      historyItem: {
        group,
        severity
      },
      index,
      isMobile,
      isShowedFullHistory
    } = this.props

    const iconClasses = cn('mr-4', 'relative', {
      [styles.icon]: isShowedFullHistory || (!isShowedFullHistory && index !== countDisplayHistoryItems - 1)
    })
    const itemClasses = cn(styles.item, 'd-flex', index === 0 && isMobile ? 'mt-4' : 'mt-5', 'font-default')

    return (
      <div className={itemClasses}>
        <div className={iconClasses}>
          {getHistoryStateIcon(group, severity)}
        </div>
        <div className='flex-grow-1'>
          {this.renderContent()}
          {this.renderComment()}
        </div>
      </div>
    )
  }

  private readonly renderContent = (): ReactElement => {
    const {
      historyItem: {
        historyStateCaption,
        historyTimestamp,
        subOrgName,
        user
      },
      isMobile
    } = this.props

    const timestamp = historyTimestamp ? DateTime.fromMillis(historyTimestamp).toFormat(DateFormat.DATETIME) : ''

    return (
      <div>
        <p className='my-0'>
          <span className={cn('fw-600', {'d-block': isMobile})}>{this.renderTitle()}</span>
          {historyStateCaption}
        </p>
        <p className='text-muted font-xs my-0'>
          <span className='mr-2'>{timestamp}</span>
          <span>{user ? user.position : subOrgName}</span>
        </p>
      </div>
    )
  }

  private readonly renderComment = (): ReactNode => {
    const {
      historyItem: {
        comment
      },
      t
    } = this.props

    const { isCommentOpen } = this.state

    if (!comment) return null

    return (
      <>
        {isCommentOpen && (
          <Comment
            button={t('document:history.comment.readMore')}
            classes={styles.comment}
            text={comment}
            type={LibCommentType.INCOMING}
          />
        )}
        {this.renderCommentButton()}
      </>
    )
  }

  private readonly renderTitle = (): ReactNode => {
    const {
      historyItem: {
        orgWhereHappenedName,
        user
      },
      isMobile
    } = this.props

    return (
      `${orgWhereHappenedName}, ${user ? `${getSurnameWithInitials(user)}${!isMobile ? ', ' : ''}` : ''}`
    )
  }

  private readonly renderCommentButton = (): ReactElement => {
    const {
      t
    } = this.props

    const { isCommentOpen } = this.state

    return (
      <Button size='small' onClick={this.handleCommentButtonClick} theme='link'>
        {t(`document:history.comment.${isCommentOpen ? 'hide' : 'show'}`)}
      </Button>
    )
  }

  private readonly handleCommentButtonClick = (): void => {
    this.setState((prevState: IState) => ({
      ...prevState,
      isCommentOpen: !prevState.isCommentOpen
    }))
  }
}


export default withTranslation()(HistoryItem)
