import React, { FC, Suspense } from 'react';
import { Provider } from 'react-redux'

import SpinnerWrapper from '@common/SpinnerWrapper'
import LayoutWrapper from '@common/Layout/components/LayoutWrapper'

import store from '@store/configureStore'

import '@infologistics/frontend-libraries/dist/styles/styles.css'
import './App.css';


const App: FC = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<SpinnerWrapper />}>
        <LayoutWrapper />
      </Suspense>
    </Provider>
  )
}

export default App;
