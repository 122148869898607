import { Actions, IUtilsState, UtilsActionTypes } from './types'

export const initialState: IUtilsState = {
  documentOguid: null,
  isShownMenus: false,
  orgOguid: null
}

export default function utilsReducer (state = initialState, action: Actions): IUtilsState {
  switch (action.type) {
    case UtilsActionTypes.SET_DOCUMENT_OGUID:
      return {
        ...state,
        documentOguid: action.payload
      }

    case UtilsActionTypes.SET_ORGANIZATION_OGUID:
      return {
        ...state,
        orgOguid: action.payload
      }

    case UtilsActionTypes.TOGGLE_MENUS:
      return {
        ...state,
        isShownMenus: action.payload
      }

    default:
      return state
  }
}
