import cn from 'classnames'
import { detect } from 'detect-browser'
import React, { Component, ReactElement, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import File from './components/File'
import Footer from './components/Footer'
import History from './components/History'
import { Content, debounce, FormLabel } from '@infologistics/frontend-libraries'

import {
  Browser,
  DEBOUNCE_WAIT,
  FieldKey,
  i18nEvent,
  INNER_CLASSES,
  MOBILE_MAX_WIDTH,
  OS
} from '@const/consts'

import { displayErrorNotification, getFormattedFieldValue, getSurnameWithInitials } from '@utils/utils'

import { getDocument } from '@store/modules/shared/actions'

import { Nullable } from '@app/types'
import { IField, IFields, IUser } from '@store/modules/shared/types'
import { IApplicationState } from '@store/types'
import {
  IDocumentProps as IProps,
  IDocumentPropsFromState as IPropsFromState,
  IDocumentPropsFromDispatch as IPropsFromDispatch,
  IDocumentState as IState
} from './types'

const initialState: IState = {
  isMobile: window.innerWidth < MOBILE_MAX_WIDTH
}

const styles = require('./Document.module.css')

class Document extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  public componentDidMount(): void {
    window.addEventListener('resize', this.checkWindowWidthWithDebounce)

  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.checkWindowWidthWithDebounce)
  }

  public render(): ReactNode {
    const {
      document: {
        allowedActions
      },
      i18n
    } = this.props

    const { isMobile } = this.state

    i18n.on(i18nEvent.LANGUAGE_CHANGED, this.loadDocumentWithDebounce)

    return (
      <>
        {this.renderContent()}
        <Footer allowedActions={allowedActions} isMobile={isMobile} />
      </>
    )
  }

  private readonly loadDocument = (): void => {
    const { documentOguid, onGetDocument, orgOguid } = this.props

    if (documentOguid && orgOguid) {
      onGetDocument(orgOguid, documentOguid)
        .catch(displayErrorNotification)
    }
  }

  private readonly renderContent = (): ReactElement => {
    const { isMobile } = this.state
    const isMobileFireFox = detect()?.name === Browser.FIREFOX && detect()?.os === OS.ANDROID

    return (
      <Content classes={cn(
        styles.content,
        'ml-n4',
        isMobileFireFox ? 'mr-n2' : 'mr-n4'
      )}>
        {this.renderDocumentInfo()}
        <File isMobile={isMobile} />
      </Content>
    )
  }

  private readonly renderDocumentInfo = (): ReactNode => {
    const { document } = this.props
    const { isMobile } = this.state

    const {
      fields,
      fields: { direction },
      history,
      initiator,
      subOrgName,
      typeTitle
    } = document

    const classes = isMobile ? 'flex-column mb-5' : 'justify-content-between mb-2'

    return (
      <div className={INNER_CLASSES}>
        {this.renderTitle(direction, fields, typeTitle)}
        <div className={cn('d-flex', classes)}>
          {this.renderFields(fields)}
          {this.renderInitiatorSuborg(initiator, subOrgName)}
        </div>
        <History
          history={history}
          isMobile={isMobile}
        />
      </div>
    )
  }

  private readonly renderTitle = (direction: IField, fields: IFields, typeTitle: string): ReactElement => {
    const { t } = this.props
    const { isMobile } = this.state

    const directionKey = FieldKey.DIRECTION
    const directionValue = directionKey in fields
      ? `${getFormattedFieldValue(t, fields[directionKey].type, fields[directionKey].value)}\xa0`
      : ''

    const documentDateKey = FieldKey.DOCUMENT_DATE
    const documentDateValue = documentDateKey in fields
      ? `${t('common:from')}\xa0${getFormattedFieldValue(t, fields[documentDateKey].type, fields[documentDateKey].value)}`
      : ''

    const documentNumberKey = FieldKey.DOCUMENT_NUMBER
    const documentNumberValue = documentNumberKey in fields
      ? `№\xa0${getFormattedFieldValue(t, fields[documentNumberKey].type, fields[documentNumberKey].value)}`
      : ''

    const classes = isMobile ? 'h5 fw-600' : 'h3'

    return (
      <div className={cn(styles.title, 'mb-5')}>
        <p className={cn(classes, 'mb-2')}>
          {`${directionValue}${typeTitle} ${documentNumberValue}\xa0${documentDateValue}`}
        </p>
      </div>
    )
  }

  private readonly renderFields = (fields: IFields): ReactElement => {
    const displayedFields = {
      ...fields
    }

    if (FieldKey.DOCUMENT_DATE in displayedFields) delete displayedFields[FieldKey.DOCUMENT_DATE]
    if (FieldKey.DOCUMENT_NUMBER in displayedFields) delete displayedFields[FieldKey.DOCUMENT_NUMBER]

    return (
      <div>
        {Object.keys(displayedFields)
          .sort((a, b) => fields[a].order - fields[b].order)
          .map((key: string) => this.renderField(key, fields[key]))}
      </div>
    )
  }

  private readonly renderField = (key: string, {label, type, value}: IField): ReactElement => {
    const { t, i18n: { language } } = this.props
    const { isMobile } = this.state

    const formattedValue = getFormattedFieldValue(t, type, value, language)
    const displayedValue = formattedValue.length ? `${formattedValue}` : ''

    const classes = isMobile ? 'font-sm justify-content-between relative' : 'font-default'

    return (
      <div key={key} className={cn(styles.field, 'd-flex', 'mb-2', classes)}>
        <FormLabel className='text-muted'>
          <span className={cn({'pr-1': isMobile})}>
            {label}
          </span>
        </FormLabel>
        <p className={cn('my-0', {'text-right': isMobile})}>
          <span className={cn({'pl-1': isMobile})}>
            {displayedValue}
          </span>
        </p>
      </div>
    )
  }

  private readonly renderInitiatorSuborg = (initiator: Nullable<IUser>, subOrgName: Nullable<string>): ReactNode => {
    const { t } = this.props

    const initiatorFullName = initiator ? getSurnameWithInitials(initiator) : null

    if (!initiatorFullName && !subOrgName) return null

    return (
      <div>
        {subOrgName && this.renderAdditionalField(t('document:subdivision'), subOrgName)}
        {initiatorFullName && this.renderAdditionalField(t('document:initiator'), initiatorFullName)}
      </div>
    )
  }

  private readonly renderAdditionalField = (label: string, value: string): ReactElement => {
    const { isMobile } = this.state

    const classes = isMobile ? 'font-sm justify-content-between relative' : 'font-default flex-column'

    return (
      <div className={cn(styles.additional_field, 'd-flex', 'mb-2', classes)}>
        <FormLabel className='text-muted'>
          <span className={cn({'pr-1': isMobile})}>
            {label}
          </span>
        </FormLabel>
        <p className={cn('my-0', {'text-right': isMobile})}>
          <span className={cn({'pl-1': isMobile})}>
            {value}
          </span>
        </p>
      </div>
    )
  }

  private readonly checkWindowWidth = (): void => {
    this.setState({
      isMobile: window.innerWidth < MOBILE_MAX_WIDTH
    })
  }

  private readonly checkWindowWidthWithDebounce = debounce(this.checkWindowWidth, DEBOUNCE_WAIT)

  private readonly loadDocumentWithDebounce = debounce(this.loadDocument, DEBOUNCE_WAIT)
}


const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  documentOguid: state.utils.documentOguid,
  orgOguid: state.utils.orgOguid
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetDocument: (orgOguid: string, docOguid: string) => dispatch(getDocument(orgOguid, docOguid))
})

const DocumentClass = connect(mapStateToProps, mapDispatchToProps)(Document)

export default withTranslation(['common', 'document'])(DocumentClass)


