import cn from 'classnames'
import React, { Component, ReactNode } from 'react'
import { connect } from 'react-redux'

import { PATHNAME_SEPARATOR } from '@const/consts'

import HeaderWrapper from '../HeaderWrapper'
import Loader from './components/Loader'
import { Layout, Notifications } from '@infologistics/frontend-libraries'
import Document from '@views/Document'

import { displayErrorNotification } from '@utils/utils'

import { actions as utilsActions } from '@store/modules/utils/actions'

import { getDocument } from '@store/modules/shared/actions'

import { IApplicationState } from '@store/types'
import {
  AllLayoutWrapperProps as AllProps,
  ILayoutWrapperPropsFromState as IPropsFromState,
  ILayoutWrapperPropsFromDispatch as IPropsFromDispatch,
  ILayoutWrapperState as IState
} from './types'

const initialState: IState = {
  isDocumentLoading: true
}

const styles = require('./LayoutWrapper.module.css')

class LayoutWrapper extends Component<AllProps, IState> {
  constructor(props: AllProps) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  public componentDidMount(): void {
    const {
      onGetDocument,
      onSetDocumentOguid,
      onSetOrganizationOguid
    } = this.props
    const [ orgOguid, docOguid ] = window.location.pathname.substring(1).split(PATHNAME_SEPARATOR)

    onSetDocumentOguid(docOguid)
    onSetOrganizationOguid(orgOguid)
    onGetDocument(orgOguid, docOguid)
      .then(() => {
        this.setState({
          isDocumentLoading: false
        })
      })
      .catch(displayErrorNotification)
  }

  public render(): ReactNode {
    const { document, isShownMenus } = this.props
    const { isDocumentLoading } = this.state

    const widthClass = isShownMenus ? undefined : 'fl-layout-full'

    return (
      <>
        <Notifications />
        <Layout externalClass={cn(widthClass, styles.layout)}>
          <HeaderWrapper orgName={document?.orgName} />
          {document && !isDocumentLoading ? (
            <Document document={document} />
          ) : (
            <Loader />
          )}
        </Layout>
      </>
    )
  }
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  document: state.shared.document,
  isShownMenus: state.utils.isShownMenus
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetDocument: (orgOguid: string, docOguid: string) => dispatch(getDocument(orgOguid, docOguid)),
  onSetDocumentOguid: (oguid: string) => dispatch(utilsActions.setDocumentOguid(oguid)),
  onSetOrganizationOguid: (oguid: string) => dispatch(utilsActions.setOrganizationOguid(oguid))
})

const LayoutWrapperClass = connect(mapStateToProps, mapDispatchToProps)(LayoutWrapper)

export default LayoutWrapperClass
