import { AxiosError } from 'axios'
import i18n from 'i18next'
import { DateTime } from 'luxon'
import { TFunction } from 'react-i18next'

import { DateFormat, Instance, Languages } from '@const/consts'

import {
  showErrorNotification,
  showSuccessNotification
} from '@infologistics/frontend-libraries'

import { Nullable } from '@app/types'
import { DocumentDirection, FieldType, FieldValue } from '@store/modules/shared/types'
import {
  INameInterface,
  ISuccessNotificationOptions
} from '@utils/types'
import { DocumentDirections } from '@app/const/translations'

export const getSurnameWithInitials = (data: INameInterface): string => {
  let result = data.surname ? `${data.surname} ` : ''

  if (data.name) {
    result = result.length > 0 ? `${result}${data.name[0].toUpperCase()}.` : `${data.name} `

    if (data.patronymic) {
      result = result ? `${result}${data.patronymic[0].toUpperCase()}.` : result
    }
  }

  return result.length ? result.trim() : result
}

export const displayErrorNotification = ({ response }: AxiosError): void => {
  const {
    language = Languages.RU,
    getResource
  } = i18n

  const errorMessage = String(getResource(language, 'notification', 'errorContent'))

  showErrorNotification({
    content: response?.data?.message || errorMessage,
    title: ''
  })
}

export const displaySuccessNotification = (options: ISuccessNotificationOptions): void => {
  const { content, title } = options
  const {
    language = Languages.RU,
    getResource
  } = i18n

  showSuccessNotification({
    content: content ?? String(getResource(language, 'notification', 'successContent')),
    title: title ?? String(getResource(language, 'notification', 'successTitle'))
  })
}

export const getFormattedFieldValue = (t: TFunction, type: FieldType, value: Nullable<FieldValue>, language?: string): string => {
  const getFormattedBooleanValue = (value: FieldValue): string =>
    typeof value === 'boolean' ? (value ? t('common:yes') : t('common:no')) : ''

  const getFormattedDateValue = (value: FieldValue): string => {
    if (typeof value === 'number') {
      const luxonDate: DateTime = DateTime.fromMillis(value)
      const getUTCOffset = (luxonDate: DateTime): number => luxonDate.offset

      return luxonDate.minus({ minutes: getUTCOffset(luxonDate) }).toFormat(DateFormat.DATE_FULL_YEAR)
    }

    return ''
  }

  const getFormattedDatetimeValue = (value: FieldValue): string => {
    if (typeof value === 'number') {
      const luxonDate: DateTime = DateTime.fromMillis(value)
      const getUTCOffset = (luxonDate: DateTime): number => luxonDate.offset

      return luxonDate.minus({ minutes: getUTCOffset(luxonDate) }).toFormat(DateFormat.DATETIME_FULL_YEAR)
    }

    return ''
  }

  const getFormattedDirectionValue = (value: FieldValue): string => {
    if (value === null) return t(DocumentDirections[DocumentDirection.INTERNAL])
    else if (typeof value === 'string') return t(DocumentDirections[value])

    return ''
  }

  const getFormattedNumberValue = (value: FieldValue, language?: string): string =>
    (typeof value === 'number' ? new Intl.NumberFormat(language).format(value) : '')

  const getFormattedStringValue = (value: FieldValue): string => (typeof value === 'string' ? value : '')

  const getFormattedValue = (value: FieldValue, language?: string): string =>
    type === FieldType.NUMBER && language ? formattedValues[type](value, language) : formattedValues[type](value)

  const formattedValues = {
    [FieldType.BOOLEAN]: getFormattedBooleanValue,
    [FieldType.CONTRACTOR]: getFormattedStringValue,
    [FieldType.DATE]: getFormattedDateValue,
    [FieldType.DATETIME]: getFormattedDatetimeValue,
    [FieldType.DICTIONARY]: getFormattedStringValue,
    [FieldType.NUMBER]: getFormattedNumberValue,
    [FieldType.STRING]: getFormattedStringValue
  }

  if (type === FieldType.DIRECTION) return getFormattedDirectionValue(value)
  return type in formattedValues && value !== null ? getFormattedValue(value, language) : ''
}

export const getInstance = (): string => window.location.hostname.match(/global|eu|ru|com.br|co.za/g)?.[0] ?? Instance.GLOBAL

export const getLanguageByInstance = (): Nullable<string> => {
  switch (getInstance()) {
    case Instance.RU:
    case Instance.EU:
      return Languages.RU
    case Instance.BR:
      return Languages.PT
    case Instance.ZA:
    case Instance.GLOBAL:
      return Languages.EN
    default:
      return null
  }
}

