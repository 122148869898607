import cn from 'classnames'
import React, { FC } from 'react'
import { withTranslation } from 'react-i18next'

import { Spinner } from '@infologistics/frontend-libraries'

import  { AllLoaderProps as AllProps } from './types'

const styles = require('./Loader.module.css')

const Loader: FC<AllProps> = (props) => {
  const { t } = props

  return (
    <div className={cn(styles.loader, 'mx-auto', 'd-flex', 'flex-column', 'align-items-center')}>
      <div className={cn(styles.spinner, 'mb-4')}>
        <Spinner />
      </div>
      <p className='h3 text-muted'>
        {t('common:loading')}
      </p>
    </div>
  )
}

export default withTranslation()(Loader)

