import cn from 'classnames'
import React, { FC } from 'react'

import LanguageSelector from '@common/LanguageSelector'
import { INNER_CLASSES } from '@const/consts'
import { Ellipsis, Header } from '@infologistics/frontend-libraries'

import Logo from '../Logo'
import {
  IHeaderWrapperProps as IProps
} from './types'

const HeaderWrapper: FC<IProps> = (props) => {
  const { orgName } = props

  return (
    <Header>
      <div className={cn(INNER_CLASSES, 'd-flex', 'align-items-center')}>
        <Logo
          classes='mr-3'
        />
        <Ellipsis externalClass='font-default text-brand'>
          {orgName}
        </Ellipsis>
        <LanguageSelector
         classes='ml-auto pl-3'
        />
      </div>
    </Header>
  )
}

export default HeaderWrapper
